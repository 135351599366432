<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import { pluralize } from '/~/utils/format/string'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'rec-group-selector',
  components: {
    BaseButton,
    BaseIcon,
    BaseAvatar,
  },
  props: {
    /*
     * members|description|excludeMyGroups
     */
    features: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search Group',
    },
    excludeMyGroups: {
      type: Boolean,
      default: false,
    },
    onlyMyGroups: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    activeGroup: {
      type: String,
      default: undefined,
    },
    showPrivate: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { groups } = useGroups()

    return {
      pluralize,
      groups,
    }
  },
  data() {
    return {
      selectedGroups: [],
      short: true,
    }
  },
  computed: {
    filteredGroups() {
      const lowerSearchValue = this.searchValue.toLowerCase().trim()

      const groups = this.onlyMyGroups
        ? this.groups.my.hits
        : this.groups.all.hits

      return groups.filter(
        (group) =>
          group.name !== 'All members' &&
          group.searchValue.includes(lowerSearchValue)
      )
    },
    shortList() {
      let result = this.filteredGroups

      if (this.small && this.short) {
        result = result.slice(0, 4)
      }

      return result
    },
    showMembersCount() {
      return this.features.indexOf('members') >= 0
    },
    showDescription() {
      return this.features.indexOf('description') >= 0
    },
    users() {
      const members = {}

      this.selectedGroups.forEach((group) => {
        group.members.hits.forEach((member) => {
          members[member.id] = member
        })
      })

      return Object.keys(members).map((key) => members[key])
    },
    usersCount() {
      return this.users.length
    },
    requesting() {
      return Boolean(
        this.selectedGroups.find((group) => group.members.processing)
      )
    },
  },
  methods: {
    onGroupSelect(group) {
      if (this.small) {
        this.$emit('selected', group)
        return
      }

      const idx = this.selectedGroups.indexOf(group)

      if (idx < 0) {
        this.selectedGroups.push(group)
      } else {
        this.selectedGroups.splice(idx, 1)
      }

      if (!group.members.allRecordsLoaded) {
        group.members.refresh()
      }
    },
    async onSave() {
      this.$emit('save', this.users)
    },
  },
}
</script>

<template>
  <div class="flex flex-col">
    <div
      data-test="rec-group-selector"
      class="flex-1 space-y-[5px] overflow-y-auto"
      :class="{
        'py-[5px]': small,
        'p-5 pt-0': !small,
      }"
    >
      <div v-if="!shortList.length" class="text-center">No groups</div>
      <div
        v-for="group in shortList"
        :key="group.id"
        class="flex cursor-pointer items-center rounded-lg hover:bg-dark"
        :class="{
          'p-[5px]': small,
          'p-2.5': !small,
          'bg-eonx-neutral-50': activeGroup === group.id,
        }"
        @click="onGroupSelect(group)"
      >
        <div class="mr-5 shrink-0 items-center">
          <div
            v-if="selectedGroups.indexOf(group) >= 0"
            class="h-12 w-12 text-fg-success"
          >
            <base-icon class="text-fg-success" svg="success" size="3xl" />
          </div>
          <base-avatar
            v-else
            :size="small ? 'xse' : 'lg'"
            :src="group.imagePreview"
          />
        </div>
        <div class="flex flex-col truncate leading-tight">
          <b class="truncate">
            {{ group.name }}
          </b>
          <span v-if="showMembersCount" class="text-eonx-neutral-600">
            {{ group.totalMembers }}
            {{ pluralize(group.totalMembers, 'member') }}
          </span>
          <span v-if="showDescription" class="truncate text-eonx-neutral-600">
            {{ group.description }}
          </span>
        </div>
        <div v-if="group.isPrivate" class="ml-auto flex">
          <base-icon svg="rec/flat/lock" class="ml-2.5 shrink-0" size="xxs" />
        </div>
      </div>
      <base-button
        v-if="filteredGroups && filteredGroups.length > 5"
        class="mx-auto mt-[15px] h-[30px] w-full"
        size="md"
        look="link"
        @click="short = !short"
      >
        <span>
          {{ short ? `See all groups ${filteredGroups.length}` : 'See less' }}
        </span>
        <base-icon
          class="ml-[15px]"
          :svg="
            short
              ? 'heroicons/outline/chevron-down'
              : 'heroicons/outline/chevron-up'
          "
          size="sm"
        />
      </base-button>
    </div>
    <div class="flex py-5">
      <slot name="footer" :users-count="usersCount" :selected="selectedGroups">
        <base-button
          class="mx-auto"
          :disabled="!usersCount"
          :loading="requesting"
          @click="onSave"
        >
          Save {{ usersCount ? `(${usersCount})` : '' }}
        </base-button>
      </slot>
    </div>
  </div>
</template>
